// примениться к размеру от 991п до 1199п
@media screen and (max-width: 1199px) {
  body{
    font-size: 16px;
  }
  .wrapper {
    padding: 0 50px;
  }
  .menu {
    padding: 10px 50px;
    .logo {
      img {
        width: 90px;
      }
      p{
        font-size: 14px;
        margin-left: 20px;
      }
    }
    .address {
      font-size: 14px;
    }
    .phones {
      a {
        font-size: 24px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .header_links {
    margin: 124px 0 35px;
  }
  .main_grad {
    height: 600px;
    position: relative;
  }
  .main_page {
    padding-bottom: 0;
    position: static;
    .content {
      h1 {
        font-size: 46px;
        line-height: 60px;
        max-width: 563px;
        margin-bottom: 20px;
      }
      .sub {
        font-size: 30px;
        line-height: 41px;
      }
      .actions {
        max-width: 630px;
        margin-top: 50px;
        a {
          padding: 16px 0;
          font-size: 18px;
          letter-spacing: 0.05em;
        }
        p {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
    .img {
      right: 50px;
      width: 320px;
    }
  }
  section {
    margin-bottom: 130px;
  }
  .h2 {
    font-size: 30px;
    line-height: 42px;
    br{
      display: none;
    }
  }
  .h3 {
    font-size: 25px;
  }
  .sub_title {
    font-size: 20px;
  }
  .mb-80 {
    margin-bottom: 60px;
  }
  .photo_otchet {
    grid-gap: 50px;
    &_info {
      margin-top: 25px;
      margin-bottom: 30px;
      div {
        flex-direction: column;
        justify-content: center;
        img{
          width: 50px;
        }
        p {
          margin-top: 20px;
          text-align: center;
          margin-left: 0;
          font-size: 14px;
        }
      }
    }
  }
  .primery_links_elem {
    padding: 0 20px;
    height: 100px;
    a {
      font-size: 15px;
    }
  }
  .sotrudniki_elem {
    .-info {
      span, p {
        font-size: 14px;
      }
    }
    &_kval .-sert a img{
      height: 72px;
    }
  }
  .material {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    &_elem {
      grid-gap: 7px;
      .-info {
        font-size: 16px;
      }
      &_name {
        font-size: 20px;
      }
    }
  }
  .question_elem .-info {
    font-size: 14px;
  }
  .footer{
    &_grid {
      grid-gap: 85px;
    }
    &_elem{
      &_like{
        font-size: 14px;
        padding: 20px;
        p {
          br {
            display: none;
          }
        }
      }
      &_contacts p {
        font-size: 14px;
      }
    }
    &_prav {
      font-size: 12px;
    }
  }

//  Страница контакты
  .kontakty_elem {
    padding: 15px 30px;
    p {
      font-size: 14px;
    }
  }
  .kontakty_table table tr td{
    padding: 10px 20px;
    font-size: 14px;
  }
// О КОМПАНИИ
  .o_kompanii_facts {
    grid-gap: 60px;
  }
  .o_kompanii_elem .-info {
    span {
      font-size: 55px;
    }
    p {
      font-size: 30px;
    }
  }
  .nagrada_color {
    padding-top: 50px;
    padding-bottom: 50px;
  }
//  ОТЗЫВЫ
  .otzyvy_elem {
    padding: 40px 50px;
  }
//  ГОТОВЫЕ ОБЪЕКТЫ
  .ready_obj_gallery {
    grid-template-columns: repeat(3, 1fr);
  }
//  СТРАНИЦА ТОВАРОВ
  .simple_form {
    padding: 40px 30px;
    button{
      font-size: 14px;
    }
  }
  .tp_head_content .-slider .-first {
    height: 280px;
    .carousel__slide a {
      height: 240px;
    }
  }
  .tp_head_content {
    grid-gap: 45px;
    .-info {
      .price {
        s {
          font-size: 21px;
        }
        b {
          margin-left: 15px;
          font-size: 27px;
        }
      }
      & > b {
        margin-top: 25px;
      }
    }
  }
  .tp_doc_content {
    grid-gap: 50px;
  }

}

@media screen and (max-width: 991px) {
  .wrapper {
    padding: 0 20px;
  }
  section {
    margin-bottom: 100px;
  }
  .btn {
    min-width: auto;
  }
  .menu {
    padding: 15px 20px;
    .logo {
      font-size: 12px;
    }
    .address {
      max-width: 173px;
      font-size: 14px;
      line-height: 16px;
      &::before {
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        margin-right: 2px;
      }
    }
    .phones {
      a {
        font-size: 24px;
        line-height: 30px;
      }
      p {
        margin-top: 4px;
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .header_links {
    margin: 124px 0 50px;
    display: flex;
    justify-content: space-between;
  }
  .main_grad {
    height: 579px;
  }
  .main_page {
    .content {
      h1 {
        max-width: 441px;
        font-size: 36px;
        line-height: 46px;
      }
      .sub {
        font-size: 24px;
        line-height: 32px;
      }
      .actions {
        max-width: 462px;
        margin-top: 20px;
        justify-content: space-between;
        a {
          width: 240px;
          &:before {
            margin-right: 5px;
          }
        }
        p {
          max-width: 173px;
        }
      }
    }
    .img {
      right: 20px;
      width: 259px;
    }
  }
  .h2 {
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
    font-size: 27px;
  }
  .sub_title {
    font-size: 16px;
    line-height: 30px;
  }
  .h3 {
    font-size: 22px;
  }
  .grid-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .quiz_zagolovok p {
    font-size: 24px;
    max-width: 500px;
  }
  .drenazh_system_elem {
    .yellow_line_text {
      font-size: 18px;
    }
    span {
      font-size: 14px;
    }
  }
  .price_table_title {
    padding: 15px 0;
    font-size: 21px;
    .price_table_drop_btn{
      right: 50px;
    }
  }
  .price_table table thead tr td {
    padding: 15px 0;
    font-size: 21px;
  }
  .price_table table tbody td {
    padding-left: 20px;
    padding-right: 10px;
  }
  .question {
    grid-template-columns: 1fr;
    &_elem {
      height: 80px;
      p {
        font-size: 14px;
      }
    }
  }
  .osusheniye p {
    font-size: 15px;
  }
  .footer{
    &_grid {
      grid-template-columns: repeat(2,1fr);
      grid-row-gap: 30px;
    }
  }

//  документы
  .dokumenty {
    &_elem {
      grid-template-columns: repeat(3, 1fr);
    }
  }
//  О КОМПАНИИ
  .nagrada_color {
    padding-top: 50px;
    padding-bottom: 50px;
  }
//  СПОСОБЫ ОПЛАТЫ
  .sposoby_oplaty {
    &_bank {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  //  ОТЗЫВЫ
  .otzyvy_elem {
    padding: 40px 30px;
  }
  //  ГОТОВЫЕ ОБЪЕКТЫ
  .ready_obj_deadline_elem{
    margin-right: 30px;
    padding: 20px 30px;
  }
//  КАЛЬКУЛЯТОР
  .calc_elem_info {
    .-name {
      font-size: 22px;
    }
    .-sub_name {
      font-size: 16px;
      line-height: 26px;
    }
  }
//  СТРАНИЦА ТОВАРА
  .tp_head_content {
    grid-template-areas: "slider info" "form form";
    .-slider {
      grid-area: slider;
    }
    .-info{
      grid-area: info;
    }
    .simple_form {
      grid-area: form;
    }
  }
  .tp_har_content{
    grid-gap: 30px;
  }

}

@media screen and (max-width: 767px)  {
  .wrapper {
    padding: 0 10px;
  }
  .menu {
    position: static;
    padding: 0;
  }
  .grid-2, .grid-3, .grid-4{
    grid-template-columns: 1fr;
  }
  .flex {
    flex-wrap: wrap;
  }
  .main_grad {
    height: auto;
    padding-bottom: 37px;
  }
  .btn {
    font-size: 16px;
    width: 100%;
  }
  .bread {
    margin-top: 30px;
  }
  .h2 {
    font-size: 20px;
    line-height: 35px;
  }
  .h3 {
    font-size: 18px;
    line-height: 33px;
  }
  .politica {
    line-height: 22px;
    font-size: 13px;
  }
  .mb-80 {
    margin-bottom: 40px;
  }
  .menu {
    margin-top: 55px;
    flex-wrap: wrap;
    .burger {
      z-index: 9;
      position: fixed;
      left: 0;
      top: 0;
      padding: 10px 0;
      padding-left: 10px;
      width: 100%;
      display: block;
      background-color: #FFC93E;
      i {
        display: block;
        width: 24px;
        height: 18px;
        background-image: url("../images/icons/burger.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .address {
      display: none;
    }
    .phones {
      padding: 5px 0;
      z-index: 10;
      position: fixed;
      top: 0;
      right: 10px;
      a{
        font-size: 18px;
        line-height: 30px;
        color: #000;
      }
      p {
        display: none;
      }
    }
  }
  .header_links {
    z-index: 12;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #FFC93E;
    color: #000;
    top: -4px;
    left: -105%;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-top: 80px;
    &_tap_icon:before{
      content: none;
    }
    a {
      color: #000000;
      margin-left: 0;
      margin-bottom: 40px;
      text-align: center;
    }
    p {
      text-align: center;
      display: block;
      width: 70%;
    }
  }
  .main_page {
    margin-top: 30px;
    .content {
      h1 {
        font-size: 36px;
        line-height: 42px;
        margin-bottom: 23px;
      }
      .sub{
        max-width: 160px;
        font-size: 20px;
        line-height: 27px;
      }
      .actions {
        margin-top: 50px;
        &:before{
          z-index: 2;
          height: 50px;
          width: 50px;
          bottom: -32px;
          left: auto;
          right: 5px;
        }
        a {
          order: 2;
          width: 100%;
          z-index: 1;
        }
        p {
          width: 136px;
          margin-top: 0;
          margin-bottom: 65px;
          order: 1;
          color: #000;
          &:after, &:before{
            width: 60px;
            height: 60px;
            bottom: -60px;
          }
        }
      }
    }
    .img {
      right: 10px;
      z-index: 0;
      bottom: 90px;
      width: 190px;
    }
  }
  .quiz {
    &_wrapper {
      padding: 30px 20px;
    }
    &_zagolovok p {
      font-size: 20px;
      line-height: 30px;
    }
    &_content {
      margin-top: 30px;
    }
  }
  .photo_otchet_info{
    width: 100%;
    flex-direction: column;
    align-items: start;
    div {
      flex-direction: row;
      justify-content: start;
      align-items: center;
      margin-bottom: 15px;
      p {
        margin-top: 0;
        margin-left: 20px;
        text-align: left;
      }
    }
  }
  .photo_otchet_content a {
    width: 80%;
  }
  .price_table {
    overflow-x: auto;
    width: 100%;
  }
  .price_table_title {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding-left: 10px;
    padding-right: 50px;
    .price_table_drop_btn{
      right: 15px;
      width: 19px;
    }
  }
  .price_table table thead tr td {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding-right: 50px;
    padding-left: 10px;
  }
  .price_table table tbody td {
    padding: 13px 5px;
    font-size: 14px;
    border-right: 1px solid #DFDFDF;
    &:last-child{
      border: none;
    }
  }
  .price_table table thead tr .price_table_drop_btn {
    right: 0;
  }
  .question_elem {
    height: auto;
    padding: 20px 10px 0;
    grid-template-columns: 1fr 30px;
    p {
      padding-right: 5px;
    }
  }
  .footer_grid {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
//  ВАКАНСИИ
  .vakansii_message{
    flex-direction: column;
    padding: 40px 20px;
    &:before {
      margin-right: 0;
      margin-bottom: 20px;
    }
    a {
      display: block;
    }
  }
//  ДОКУМЕНТЫ
  .dokumenty {
    h1 {
      font-size: 26px;
    }
    &_title{
      margin-top: 45px;
      margin-bottom: 16px;
    }
    &_elem {
      grid-template-columns: 1fr;
    }
  }
//  КОНТАКТЫ
  .kontakty{
    &_name {
      margin-bottom: 20px;
    }
    &_content {
      grid-gap: 30px;
    }
  }
  .kontakty_table table tr td {
    padding: 10px 14px;
  }
//  О КОМПАНИИ
  .nagrada{
    grid-gap: 35px;
    &_color {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
//  СПОСОБЫ ОПЛАТЫ
  .sposoby_oplaty {
    &_bank {
      grid-gap: 30px;
      img {
        margin: 0;
      }
    }
  }
// СТРАНИЦА УСЛУГ
  .usluga {
    padding-bottom: 75px;
    &_content{
      position: relative;
      z-index: 0;
    }
    .usluga_img {
      z-index: -1;
      position: absolute;
    }
  }
  //  ГОТОВЫЕ ОБЪЕКТЫ
  .ready_obj_deadline{
    flex-wrap: wrap;
    &_elem {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
// КАЛЬКУЛЯТОР
  .calc_elem {
    grid-template-columns: 1fr;
    grid-gap: 22px;
    padding: 0 10px;
    .-name {
      margin-bottom: 15px;
    }
  }
  .calc_elem_range .-range_value {
    transform: translateX(-50%);
  }
  .calc_itog {
    align-items: start;
    //text-align: left;
    p {
      font-size: 24px;
      line-height: 30px;
      text-align: left;
    }
    b {
      font-size: 28px;
    }
    .-form {
      flex-wrap: wrap;
      justify-content: center;
      input {
        margin-bottom: 25px;
        margin-right: 0;
      }
    }
  }
  //  СТРАНИЦА ТОВАРА
  .tp_head_content {
    grid-template-areas: "slider" "info" "form";
    .-slider {
      grid-area: slider;
    }
    .-info{
      grid-area: info;
    }
    .simple_form {
      grid-area: form;
    }
  }
  .simple_form button {
    font-size: 17px;
  }
  .tp_har_content {
    grid-gap: 20px;
    ul li {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media screen and (max-width: 575px) {
  .popup {
    padding: 0;
    .simple_form {
      padding: 30px 20px;
    }
  }

}